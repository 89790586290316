import styled from 'styled-components';

import { container, space, fontSize } from 'theme/sizes';
import { neutral, brand } from 'theme/colors';
import { h2, h3, h4 } from 'theme/typography';

import { breakpoints, gridSize, toRem } from 'utils/mixins';

export const WrapperHorizontal = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding: ${space.m} ${space.s};
  margin-top: ${space.l};
  background-color: ${neutral.neu04};

  @media ${breakpoints.laptop} {
    padding: ${space.xxl} ${space.m};
  }
`;

export const ContentHorizontal = styled.div`
  width: 100%;
  max-width: ${container.xsmall};
  margin: 0 auto;

  .content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 0;
    max-width: 100%;

    &__info {
      h2 {
        ${h4}
        margin-bottom: ${space.xs};
      }

      h4 {
        ${h2}
        position: relative;
        margin-bottom: ${space.s};
        text-transform: none;

        &::after {
          transform: ${`translateY(${space.xxs})`};
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 66%;
          height: ${space.tiny};
          border-radius: ${space.xxs};
          background-color: ${brand.main};
        }
      }

      p {
        margin: 0;
        margin-bottom: ${space.m};
      }
    }
  }

  .accordion {
    border: ${toRem(1)} solid ${neutral.neu05};
  }

  .accordion__item + .accordion__item {
    border-top: ${toRem(1)} solid ${neutral.neu05};
  }

  .accordion__heading {
    position: relative;
  }

  .accordion__button {
    background-color: ${neutral.neu01};
    color: ${neutral.neu09};
    cursor: pointer;
    padding: ${space.xs};
    width: 100%;
    text-align: left;
    border: none;
    display: flex;
    align-items: center;
    transition: 220ms;

    p {
      margin: 0;
    }
  }

  .accordion__button::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: ${toRem(1)};
    transform: scaleX(0);
    transform-origin: left;
    transition: 220ms;
    background-color: ${brand.main};
  }

  .accordion__button:hover {
    &::after {
      transform: scaleX(1);
    }
  }

  .accordion__button:before {
    display: inline-block;
    content: '';
    height: ${toRem(10)};
    width: ${toRem(10)};
    margin-right: ${toRem(12)};
    border-bottom: ${toRem(2)} solid currentColor;
    border-right: ${toRem(2)} solid currentColor;
    transform: rotate(-45deg);
    transition: 220ms;
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    padding: ${space.xs};
    background-color: ${neutral.neu02};
    border-top: ${toRem(1)} solid ${neutral.neu05};

    p {
      margin: 0;
    }
  }

  @media ${breakpoints.tablet} {
    .content {
      max-width: 66%;
    }
  }

  @media ${breakpoints.laptop} {
    width: calc(100% - 140px);

    .content {
      margin-bottom: ${space.l};
    }

    .accordion__button {
      padding: ${space.s};
    }

    .accordion__panel {
      padding: ${space.s};
    }
  }
`;
