import React from 'react';

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import {
  Wrapper,
  Content,
  WrapperHorizontal,
  ContentHorizontal,
} from './Faqs.styles';
import HTMLReactParser from 'html-react-parser';

const Faqs = (props) => {
  //PROPS
  const {
    data: { title, subtitle, content, list, faqs },
  } = props;
  //PROPS

  return (
    <WrapperHorizontal>
      <ContentHorizontal>
        <div className="content">
          <div className="content__info">
            <h2>{title}</h2>
            <h4>{subtitle}</h4>
            {HTMLReactParser(content)}
          </div>
        </div>
        <Accordion>
          {faqs.map(({ faqQuestion, faqAnswer }, index) => {
            return (
              <AccordionItem key={index}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    {faqQuestion && HTMLReactParser(faqQuestion)}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  {faqAnswer && HTMLReactParser(faqAnswer)}
                </AccordionItemPanel>
              </AccordionItem>
            );
          })}
        </Accordion>
      </ContentHorizontal>
    </WrapperHorizontal>
  );
};

export default Faqs;
