import React from 'react';

import { graphql } from 'gatsby';

import Hero from 'components/hero';
import ContactsBanner from 'components/contacts_banner';
import Form from 'components/form';
import Faqs from 'components/faqs';

const TemplateContacts = (props) => {
  //PROPS
  const {
    data: {
      page: {
        hero: { title, text, subtitle },
        contacts: {
          bannerColumn,
          form,
          bannerSubtitle,
          bannerText,
          bannerTitle,
          bannerList,
          faqs,
        },
      },
    },
  } = props;
  //PROPS

  return (
    <main className="main">
      <Hero data={{ title, subtitle, text }} center />
      <Form data={form} />
      <ContactsBanner data={bannerColumn} />
      <Faqs
        horizontal
        data={{
          title: bannerTitle,
          subtitle: bannerSubtitle,
          content: bannerText,
          list: bannerList,
          faqs: faqs,
        }}
      />
    </main>
  );
};

export const query = graphql`
  query Contacts($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      hero {
        title
        text
        subtitle
      }
      contacts {
        bannerColumn {
          content
          subtitle
          tipo
          title
        }
        bannerSubtitle
        bannerText
        bannerTitle
        form
        faqs {
          faqQuestion
          faqAnswer
        }
      }
    }
  }
`;

export default TemplateContacts;
