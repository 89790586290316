import styled from 'styled-components';

import { brand } from 'theme/colors';
import { toRem, breakpoints } from 'utils/mixins';
import { container, space, fontSize } from 'theme/sizes';

export const Wrapper = styled.section`
  max-width: ${container.xsmall};
  margin: ${space.xl} auto 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 ${space.xs};

  @media ${breakpoints.laptop} {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: ${space.s};

  .column__icon {
    width: ${space.l};
    height: ${space.l};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${brand.secondary};
    margin-bottom: ${space.xs};
    border-radius: 100%;
    font-size: ${fontSize.m};
    line-height: 1;
  }

  p {
    margin: 0;
    margin-bottom: ${space.xs};
    text-align: center;
  }

  @media ${breakpoints.tablet} {
    max-width: ${toRem(360)};
  }
`;
