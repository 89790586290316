import React from 'react';

import HTMLReactParser from 'html-react-parser';
import { MdOutlineEmail, MdLocationPin, MdLocalPhone } from 'react-icons/md';

import { Wrapper, Column } from './ContactsBanner.styles';

const ContactsBanner = (props) => {
  //PROPS
  const { data } = props;
  //PROPS

  const icon = (tipo) => {
    switch (tipo) {
      case 'email':
        return <MdOutlineEmail />;
      case 'address':
        return <MdLocationPin />;
      case 'phone':
        return <MdLocalPhone />;
    }
  };
  return (
    <Wrapper>
      {data.map((col, index) => {
        const { content, subtitle, tipo, title } = col;

        return (
          <Column key={index}>
            <div className="column__icon">{icon(tipo)}</div>
            <h2>{title}</h2>
            <p>{subtitle}</p>
            {content && HTMLReactParser(content)}
          </Column>
        );
      })}
    </Wrapper>
  );
};

export default ContactsBanner;
